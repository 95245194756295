import { screen } from "@testing-library/react";
import React from "react";

const Seo = () => {
  const descriptionSEO = (
    <>
      <h2 className="description fontS">
        Para posicionar un sitio web en Google se requiere de un estudio más
        avanzado. Evaluando el mercado y sus competencias, palabras claves,
        búsquedas relacionadas por los internautas. Y también tener en cuenta la
        velocidad de carga del sitio web, las imágenes, entre otras cosas.
        <br /> <br />
        <strong>
          Los beneficios principales son: incrementar el número de visitas y
          aumentar los beneficios económicos.
        </strong>
      </h2>
    </>
  );

  const imgSEO = <div className="imgBody imgSeo imgPCLeft"></div>;

  return (
    <div className="row">
      <h1 className="titleSection titleSectionRight colorSpecial fontL">
        <span className="fontS">4.</span>SEO
      </h1>

      <div className="col-12 col-md-6 imgIgnoreMovil">{imgSEO}</div>
      <div className="col-12 col-md-6">{descriptionSEO}</div>
      <div className="col-12 col-md-6 imgIgnorePC">{imgSEO}</div>
    </div>
  );
};

export default Seo;
