import React from "react";
import '../footer/footer.scss';
import { BsFillTelephoneOutboundFill, BsEnvelope } from "react-icons/bs";
import { SiGooglemaps } from "react-icons/si";
import BodyMe from "../body/body4me/bodyMe";

const Contact = () => {
  return (
    <div className="container-fluid">
      <div className="sectionContact">
          <h3 className="titleContact fontL">CONTACTO</h3>
   
        <div className="row a">
          <div className="col-sm-12
                          col-md-12
                          col-lg-4 
                          optionsContact">
            Telefono <BsFillTelephoneOutboundFill />
            <br />
            <span className="colorHidden">+34</span> 680 338 659
          </div>
          <div className="col-sm-12
                          col-md-12
                          col-lg-4 
                          optionsContact">
            Email <BsEnvelope /> <br />
            info@RomelZela.com
          </div>
          <div className="col-sm-12
                          col-md-12
                          col-lg-4 
                          optionsContact">
            Ubicación <SiGooglemaps /> <br />
            Madrid, España
          </div>
        </div>
        </div>
    </div>
  );
};

export default Contact;
