import React from 'react'
import '../../../styles/fontsize.scss';


const DesignWeb = () => {

  const descriptionDesign = (
    <>
                <h2 className='description fontS'> 
                El diseño web es una tarea muy importante para garantizar al usuario una experiencia agradable e intuitiva con la finalidad de 
                alcanzar los objetivos establecidos como: vender un producto, informar sobre alguna novedad, ofrecer algún servicio, entre otros. 
                <br /> <br />
                <strong>El diseño tendrá en cuenta los diversos dispositivos, lo que permite una adaptación adecuada entre móviles, tablets y ordenadores.</strong>
            
                </h2>
    </>
  )

  const imgDesign = (
    <div className='imgBody imgDesign imgPCRight'></div>

  )


  return (
    <div className="row">
      <h1 className="titleSection colorSpecial fontL">
        <span className="fontS">1.</span>DISEÑO
      </h1>
      <div className="col-12 col-md-6">{descriptionDesign}</div>
        <div className="col-12 col-md-6">{imgDesign}</div>
    </div>
  );
}

export default DesignWeb