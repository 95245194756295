import React from 'react'
import GoBackToHome from './goBackToHome'
import './goBack.scss'

const GeneralPrivacy = () => {
  return (
    <div>
    <GoBackToHome />
    
    <span className='bodyPrivacy'>

    CONDICIONES GENERALES DE UTILIZACIÓN DEL SITIO WEB https://RomelZela.com 
      
    <br/> <br/>
    
    1. INFORMACIÓN GENERAL 
Las presentes Condiciones Generales regulan el uso (incluyendo el mero acceso) y el funcionamiento del sitio Web de https://RomelZela.com 
En cumplimiento de lo previsto en la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y de Comercio Electrónico, el sitio web xxx_zzzz.com es propiedad de “Nombre y Apellidos” (en adelante, “Nombre y Apellidos”), con domicilio a efectos de notificaciones en C/Parvillas Bajas, NIF Y5764369P y correo electrónico info@romelzela.com
<br/> <br/>
2. ACEPTACIÓN DE LAS CONDICIONES DE USO 
La utilización de este sitio Web atribuye al navegante la condición de Usuario del sitio, lo cual implica la adhesión a las presentes Condiciones Generales en la versión publicada en el momento en que se acceda al mismo. Por ello, “Romel Zela” recomienda al Usuario leer las mismas atentamente cada vez que acceda al sitio Web. 
En consecuencia, será responsabilidad de todo visitante y/o Usuario la atenta lectura de las presentes Condiciones Generales de uso vigentes en cada una de las ocasiones en que acceda a este sitio Web, por lo que si éste no está de acuerdo con cualquiera de las condiciones aquí dispuestas, deberá abstenerse respecto al uso del presente sitio Web. 
"Romel Zela" podrá alterar en cualquier momento y sin previo aviso al usuario, el diseño, presentación y/o configuración del sitio Web, así como algunos o todos los Contenidos, y modificar las condiciones generales y/o condiciones particulares requeridas para utilizar los mismos. 
<br/> <br/>
3. USO DEL SITIO 
El Usuario es consciente y acepta voluntaria y expresamente que el uso del sitio https://romelzela.com se realiza en todo caso bajo su única y exclusiva responsabilidad. 
En la utilización del sitio https://romelzela.com, el Usuario se compromete a no llevar a cabo ninguna conducta que pudiera dañar la imagen, los intereses y los derechos de "Romel Zela" o de terceros o que pudiera dañar, inutilizar o sobrecargar el sitio, o que impidiera, de cualquier forma, la normal utilización del sitio. 
Los Contenidos del sitio https://romelzela.com son puestos a disposición del Usuario con información procedente tanto de fuentes propias como de terceros. 
La inclusión de Contenidos en el sitio https://romelzela.com no constituye en modo alguno la prestación de un servicio. El Usuario reconoce que, en la medida en que la aplicación del Derecho a casos concretos no es automática, sino que puede variar en función de circunstancias muy diversas. Por ello,"Romel Zela" desaconseja al Usuario la toma de decisiones sobre la base de la información recogida en los Contenidos sin obtener un asesoramiento profesional adecuado.
"Nombre Marca" no será responsable del uso que terceros hagan de la información publicada en el portal, ni tampoco de los daños sufridos o pérdidas económicas que, de forma directa o indirecta, produzcan o puedan producir perjuicios económicos, materiales o sobre datos, provocados por el uso de dicha información.  
<br/> <br/>
4. PROPIEDAD INDUSTRIAL E INTELECTUAL 
El Usuario reconoce y acepta mediante estas Condiciones Generales que todos los derechos de propiedad industrial e intelectual sobre los contenidos y/o cualesquiera otros elementos insertados en este sitio Web (incluyendo sin limitación, marcas, logotipos, nombres comerciales, textos, imágenes, gráficos, diseños, sonidos, bases de datos, software, diagramas de flujo, presentación, "look-and-feel", audio y vídeo), son propiedad exclusiva de "Nombre Marca" y/o de terceros, quienes tienen el derecho exclusivo de utilizarlos en el tráfico económico. 
En ningún caso el acceso al sitio Web implica ningún tipo de renuncia, transmisión, licencia o cesión total ni parcial de dichos derechos, salvo que se establezca expresamente lo contrario. Las presentes Condiciones Generales de Uso del sitio Web no confieren a los Usuarios ningún otro derecho de utilización, alteración, explotación, reproducción, distribución o comunicación pública del sitio Web y/o de sus contenidos distintos de los aquí expresamente previstos. Cualquier otro uso o explotación de cualesquiera derechos estará sujeto a la previa y expresa autorización específicamente otorgada a tal efecto por Romel Zela o el tercero titular de los derechos afectados. 
Las reclamaciones que pudieran interponerse por los Usuarios o terceros en relación con posibles incumplimientos de los derechos de propiedad intelectual o industrial sobre cualquiera de los contenidos de este sitio Web deberán dirigirse a la siguiente dirección de correo electrónico info@romelzela.com con la siguiente información: 
1.-Nombre y apellidos, dirección postal y dirección de correo electrónico del afectado o, en su caso, de la persona autorizada para actuar en su nombre, indicando el título en virtud del cual ostenta la representación (en adelante, el reclamante). 
2.-Declaración por parte del reclamante en la que afirme ser el titular de los derechos supuestamente infringidos, incluyendo su firma, física o digital. 
3.- Descripción precisa de los contenidos protegidos por los derechos de propiedad intelectual supuestamente infringidos, así como su ubicación exacta dentro del sitio Web. 
4.- Declaración expresa por parte del reclamante de que la utilización de los contenidos se ha realizado sin el consentimiento del titular de los derechos supuestamente infringidos. 
4.1 Nombres de dominio 
En el mismo sentido de lo referido en el apartado anterior, el nombre de dominio https://romelzela.com y todos aquellos que sirvan para acceder de forma directa al presente sitio son de titularidad exclusiva de “Romel Zela". La indebida utilización de los mismos en el tráfico económico supondría una infracción de los derechos conferidos por su registro y será perseguido por los medios previstos en la normativa legal.
4.2 Derechos de Autor 
Los contenidos, textos, fotografías, diseños, logotipos, imágenes, sonidos, vídeos, animaciones, grabaciones, programas de ordenador, códigos fuente y, en general, cualquier creación intelectual existente en este sitio, así como el propio sitio en su conjunto, como obra artística multimedia, están protegidos como derechos de autor por la legislación en materia de propiedad intelectual. 
4.3 Uso personal 
"Nombre Marca" autoriza a los Usuarios a utilizar, visualizar, obtener una copia temporal, descargar y almacenar los contenidos y/o los elementos insertados en el sitio Web exclusivamente para su uso personal, privado y no lucrativo; siempre que en todo caso se indique el origen y/o autor de los mismos y que, en su caso, aparezca el símbolo del copyright y/o notas de propiedad industrial de sus titulares. 
Queda terminantemente prohibida la utilización de tales elementos, su reproducción, comunicación y/o distribución con fines comerciales o lucrativos, así como su modificación, alteración, o descompilación. 
Para cualquier otro uso distinto de los expresamente permitidos, será necesario obtener el consentimiento previo por escrito del titular de los derechos de que se trate. 
4.4 Reserva de acciones 
El Usuario de este sitio Web se compromete a respetar los derechos enunciados y a evitar cualquier actuación que pudiera perjudicarlos, reservándose en todo caso "Romel Zela" el ejercicio de cuantos medios o acciones legales le correspondan en defensa de sus legítimos derechos de propiedad intelectual e industrial.
<br/> <br/>
5. EXCLUSIÓN DE GARANTÍAS Y RESPONSABILIDAD 
5.1 Exclusión de garantías y de responsabilidad por el funcionamiento del Portal 
"Romel Zela" no garantiza la disponibilidad y continuidad del funcionamiento del sitio Web. Asimismo, "Romel Zela" no será en ningún caso responsable por cualesquiera daños y perjuicios que puedan derivarse de: 
1. La falta de disponibilidad o accesibilidad al sitio Web; 
2. La interrupción en el funcionamiento del sitio Web o fallos informáticos, averías telefónicas, desconexiones, retrasos o bloqueos causados por deficiencias o sobrecargas en las líneas telefónicas, en el sistema de Internet o en otros sistemas electrónicos producidos en el curso de su funcionamiento; 
3. La falta de idoneidad del sitio Web para las necesidades específicas de los Usuarios y;
4. Otros daños que puedan ser causados por terceras personas mediante intromisiones no autorizadas ajenas al control de "Romel Zela". 
No se garantiza la ausencia de virus ni de otros elementos en el sitio Web introducidos por terceros ajenos a "Romel Zela" que puedan producir alteraciones en los sistemas físicos o lógicos de los Usuarios o en los documentos electrónicos y ficheros almacenados en sus sistemas. 
"Romel Zela" adopta diversas medidas de protección para proteger el sitio Web y los contenidos contra ataques informáticos de terceros. No obstante, "Romel Zela" no garantiza que terceros no autorizados no puedan tener acceso al tipo de uso del sitio Web que hace el Usuario o las condiciones, características y circunstancias en las cuales se realiza dicho uso. En consecuencia, no será en ningún caso responsable de los daños y perjuicios que pudieran derivarse de dicho acceso no autorizado. 
5.2 Exclusión de garantías y de responsabilidad por la utilización del Portal de los servicios y de los contenidos por los Usuarios 
"Romel Zela" no se hará responsable en ningún caso del uso que los Usuarios y/o terceros pudieran hacer del sitio Web o los contenidos, ni de los daños y perjuicios que pudieran derivarse del mismo. 
"Romel Zela" excluye cualquier responsabilidad por los daños y perjuicios de toda naturaleza que pudieran deberse a la utilización de los servicios y de los contenidos por parte de los Usuarios o que puedan deberse a la falta de veracidad, vigencia, exhaustividad y/o autenticidad de la información que los Usuarios proporcionan acerca de si mismos y en particular, aunque no de forma exclusiva, por los daños y perjuicios de toda naturaleza que puedan deberse a la suplantación de la personalidad de un tercero efectuada por un Usuario en cualquier clase de comunicación realizada a través del portal. 
5.3 Exclusión de garantías y de responsabilidad por los Contenidos 
"Romel Zela" no será en ningún caso responsable de cualesquiera daños y perjuicios que pudieran derivarse de: 
1. Los daños y perjuicios de toda naturaleza que puedan deberse a la falta de licitud, fiabilidad, utilidad, veracidad, exactitud, exhaustividad y actualidad de los contenidos. 
2. La inadecuación para cualquier propósito y la defraudación de las expectativas generadas por los Contenidos. 
<br/> <br/>
6. DATOS DE CARÁCTER PERSONAL 
Por el mero hecho de visitar el sitio Web https://romelzela.com no queda registrado de forma automática ningún dato de carácter personal que identifique a un Usuario. Sin embargo, existe determinada información de carácter no personal y no identificable con un Usuario concreto que puede recogerse y quedarse grabada en los servidores de Internet de "Romel Zela"( por ejemplo, tipo de navegador de Internet del Usuario y sistema operativo del Usuario) con el objeto de mejorar la navegación del Usuario y la gestión del sitio https://romelzela.com. 
"Nombre Marca" podrá requerir del Usuario ciertos datos de carácter personal para la utilización de determinados contenidos o servicios, cumpliendo en todo momento el Reglamento UE 2016/679 y Ley Orgánica 3/2018 de 5 de diciembre de protección de datos de carácter personal y garantía de los derechos digitales. 
<br/> <br/>
7. MODIFICACIÓN Y TERMINACIÓN DE SERVICIOS 
La duración de este sitio es, en principio, indeterminada, no obstante "Romel Zela" se reserva el derecho de modificar, suspender o dar por terminada la prestación de sus servicios, en cualquier momento y sin necesidad de previo aviso, así como las presentes Condiciones Generales. 
<br/><br/>
8. LEY Y JURISDICCIÓN APLICABLE 
Las presentes Condiciones Generales de Uso se rigen por las Leyes españolas. Cualquier controversia en relación con el sitio Web de https://romelzela.com se sustanciará ante la jurisdicción española, sometiéndose las partes a los Juzgados y Tribunales de la ciudad de Burgos, y sus superiores jerárquicos, con expresa renuncia a otros fueros si lo tuvieren y fueran diferentes de los reseñados. 
El modo más sencillo y eficaz para solicitar cualquier aclaración, o para formular cualquier tipo de queja, sugerencia o comentario, es mediante el envío de un e-mail a la dirección info@romelzela.com
El hecho de acceder a este sitio Web y usarlo implica que acepta las presentes Condiciones Generales. 
Copyright 2022. "Romel Zela" Todos los derechos reservados y registrados. Prohibida su reproducción total o parcial.


    </span> </div>
  )
}

export default GeneralPrivacy