import React from "react";

const Maintenance = () => {
  const descriptionMaintenance = (
    <>
      <h2 className="description fontS">
        Mantener un sitio web seguro es un trabajo constante que requiere de
        actualizaciones de hasta dos veces por semana. Especialmente las
        desarrolladas con WordPress o PrestaShop.
        <br /> <br />
        <strong>
          Un sitio web actualizado garantiza la protección de los datos, el
          cumplimiento con las leyes digitales, y protección a posibles hackers.{" "}
        </strong>
      </h2>
    </>
  );

  const imgMaintenance = <div className="imgBody imgMainte imgPCRight"></div>;
  return (
    <div className="row">
      <h1 className="titleSection colorSpecial fontL">
        <span className="fontS">3.</span>MANTENIMIENTO
      </h1>

      <div className="col-12 col-md-6">{descriptionMaintenance}</div>
      <div className="col-12 col-md-6">{imgMaintenance}</div>
    </div>
  );
};

export default Maintenance;
