import React from 'react'

const Portfolio = () => {


  return (
    <>
    <h1 className='descriptionMe colorSpecial'> En construcción... </h1>
    <div className='row'>
      <div className='col-2 cardPortfolio'>cargando...</div>
      <div className='col-2 cardPortfolio'>cargando...</div>
      <div className='col-2 cardPortfolio'>cargando...</div>
      <div className='col-2 cardPortfolio'>cargando...</div>
      <div className='col-2 cardPortfolio'>cargando...</div>
    </div>
    </>
  )
}

export default Portfolio