import React from 'react'
import './goBack.scss'
import {Link} from 'react-router-dom';

const GoBackToHome = () => {
  return (
    <div>
        <div className='navBackToHome'>
        <Link className='linkBackToHome' to="/">Ir a inicio</Link>
        </div>

    </div>
  )
}

export default GoBackToHome