import React, { useState } from 'react'
import '../footer/footer.scss'


const InputContact = (color) => {
    const [message, setMessage] = useState('');

    const typeWriter = async (selector_target, text_list, placeholder = false, i = 0, text_list_i=0, delay_ms=100) => {

      await selector_target;

    if (!i) {
        if (placeholder) {
          document.querySelector(selector_target).placeholder = "";
        }
        else {
          document.querySelector(selector_target).innerHTML = "";
        }
      }
      let txt = text_list[text_list_i];
      if (i < txt.length) {
        if (placeholder) {
          document.querySelector(selector_target).placeholder += txt.charAt(i);
          
        }
        else {
          document.querySelector(selector_target).innerHTML += txt.charAt(i);
        }
        i++;

        setTimeout(typeWriter, delay_ms, selector_target, text_list, placeholder, i, text_list_i);
      }
      else {
        text_list_i++;
        if (typeof text_list[text_list_i] === "undefined")  {
            // set "return;" for disabled infinite loop 
            return;
          }
          else {
            i = 0;
            setTimeout(typeWriter, (delay_ms*3), selector_target, text_list, placeholder, i, text_list_i);
          }
    }

}

let text_list = [
    "CONTÁCTAME",
    "INTRODUCE TU MENSAJE",
    
];


    typeWriter("#inputMessage", text_list, true);

    
   

    
    const whatsApp = () => {
        
        const messageCode = encodeURI(message);
        const linkWs = `https://api.whatsapp.com/send?phone=34680338659&text=${'Hola,%20Romel.%20El%20asunto%20es:%20'} ${messageCode}`;
        window.location.href = (linkWs)

    }
    
    const email = (e) => {

      e.preventDefault();
      window.location.href =`mailto:info@romelzela.com?subject=Quiero%20información&body=${message}`;


    }


  return (
    <div>
        <form className='contactForm' >
            <input className='inputMessage' id='inputMessage' type='text' name='inputMessageSubject' onBlur={(e) => setMessage(e.target.value)} />
            <button type="button" className='buttonWs' onClick={whatsApp}>Enviar por WhatsApp</button>
            <button type="button" className='buttonEmail' onClick={email}>Enviar por Email</button>
        </form>
    </div>
  )
}

export default InputContact