import React from 'react';
import './main.scss';
import '../../styles/fontsize.scss';
import InputContact from '../contact/inputContact';
import '../contact/contact.scss'
import {IoIosArrowDown} from 'react-icons/io';

const Main = () => {

  
  const typeOffer = async () => {
      await window.onload;

        document.getElementById('alertOfferBG').animate([
          {transform: 'translateY(-5vh'},
          {transform: 'translateY(0vh)'}
        ],{
          duration: 3000
        },)
        document.getElementById("offerMain").animate([
          {transform: 'translateX(60vw)'},
          {color:'white'},
          {color:'orange'},
          {color:'white'},
          {color:'white'},
          {transform: 'translateX(-40vw)'},
        ],{
          duration: 10000,
          iterations: Infinity,
          
        })
      }
    
      typeOffer();

      const arrowDownScroll = async () => {
        
        await window.onload;

        document.getElementById('arrowDownScroll').animate([
        {transform: 'translateY(-10px)'},
        {transform: 'translateY(0px)'},
        {color: 'orange'},
        {transform: 'translateY(-10px)'},
      ],
        {
          duration:2000,
          iterations: Infinity
        })

      };
      arrowDownScroll();
      
      
      const fun = async () => {
        await window.onload
        document.getElementById('bodyPresentation').scrollIntoView();
      }




    
  return (


    <div className='container-fluid backgroundPrincipal'>
        <div className='textMain row'>

          <div className='col-12'>

            <h1 className='titleMain fontL'>DESARROLLO WEB</h1>

            <h2 className='descriptionMain colorHidden fontS'>Diseño | Desarrollo | Mantenimiento | SEO</h2>

            <div className='alertOfferBG' id='alertOfferBG'>
    
            <h3 id="offerMain" className=' offerMain'>OFERTA WEB <span className='fontXS'>DESDE</span> 280€</h3>
    </div>
            </div>
              <InputContact />
        </div>
        <IoIosArrowDown id='arrowDownScroll' size={55} onClick={fun} />
    </div>

  )
}

export default Main