import './styles/App.scss';
import {Route, Routes} from 'react-router-dom'
import Home from './home';
import CookiesPrivacy from './components/footer/privacy/cookiesPrivacy';
import GeneralPrivacy from './components/footer/privacy/generalPrivacy';
import LegalPrivacy from './components/footer/privacy/legalPrivacy';

function App() {


  return (
    <div className="App">
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/privacyCookies" element={<CookiesPrivacy />} />
      <Route path='/generalPrivacy' element={<GeneralPrivacy />} />
      <Route path='/legalPrivacy' element={<LegalPrivacy />} />

    </Routes>
    </div>
  );
}

export default App;
