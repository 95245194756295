import React from 'react'

const Education = () => {
  return (
    <table className='descriptionMe'> 
                            <thead>
                                <tr>
                                    <th className='titlesAboutme colorSpecial'>Fecha</th>
                                    <th className='titlesAboutme colorSpecial'>Título</th>
                                    <th className='titlesAboutme colorSpecial'>Escuela</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>2022 - 2022</td>
                                    <td>Bootcamp React</td>
                                    <td>Open Bootcamp</td>
                                </tr>
                                <tr>
                                    <td>2021 - 2022</td>
                                    <td>Bootcamp .Net</td>
                                    <td>Nunsys</td>
                                </tr>
                                <tr>
                                    <td>2019 - 2021</td>
                                    <td>Fp+ DAW</td>
                                    <td>Ilerna</td>
                                </tr>
                                <tr>
                                    <td>2019 - 2021</td>
                                    <td>Fp+ DAM</td>
                                    <td>Ilerna</td>
                                </tr>
                                <tr>
                                    <td>2010 - 2015</td>
                                    <td>Grado en psicología</td>
                                    <td>UAM</td>
                                </tr>
                            </tbody>    
                        
                        </table> 
  )
}

export default Education