import React from 'react'

const AboutMe = () => {
  return (
    <h1 className='descriptionMe fontS'><span className="colorSpecial">Hola, soy Romel. Programador y psicólogo. <br /></span>
    <br/>Terminé mis estudios como licenciado en psicología en el 2015. Desde ese momento, empecé a trabajar como psicólogo organizacional,
    enfocándome en mejorar las relaciones de trabajo y la actitud de cada integrante del equipo para poder lograr mejores resultados.<br/><br/> En el 2018,
    decido emigrar a Madrid, encontrándome con la disyuntiva de seguir ejerciendo como psicólogo, o desarrollarme como programador web que tanto
    había deseado desde que era adolescente. Y no fue hasta el 2019 que empezó mi proceso de transformación. De psicólogo a programador. <br /><br />En el 2019 
    inicié mis primeros estudios oficiales titulados "FP+ DAW" y "FP+ DAM". Posterior a su culminación y para profundizar aún más mis conocimientos,
    decido en el 2021-2022 realizar dos bootcamps: "Bootcamp .net" y "Bootcamp React". Desde entonces, <span className='colorSpecial'> me encuentro desarrollando aplicaciones
    web como freelance, utilizando JavaScript en la librería de React.</span> Respetando los principios S.O.L.I.D. <br/> <br/>
    En el proceso<span className='colorSpecial'> también he aprendido y he utilizado para mis trabajos de freelance tecnologías como, PHP, NodeJS, SQL, HTML, CSS</span></h1>
  )
}

export default AboutMe