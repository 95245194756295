import React, { useState } from "react";
import "./bodyEntrep.scss";
import "../body.scss";
import { IoIosArrowDropright } from "react-icons/io";
import { IoIosArrowDropleft } from "react-icons/io";

const BodyEntrep = () => {
  const [indexArray, setIndexArray] = useState(0);
  const [reasons, setReasons] = useState(0);

  const tableEntrep = [
    {
      service: "Código QR",
      ad1: "Evita el contacto.",
      ad2: "Actualiza la carta rápidamente.",
      ad3: "Reduce tiempos de espera.",
      ad4: "Acelera el servicio.",
      imgEntrep: "../../../img/qr.png",
    },
    {
      service: "Venta online",
      ad1: "Consigue presencia en internet.",
      ad2: "Aumenta el alcance de tu negocio.",
      ad3: "Vende online sin comisiones.",
      ad4: "Incrementa las ventas.",
      imgEntrep: "../../../img/online.png",
    },
    {
      service: "Calendario de reservas",
      ad1: "Mejora el control del stock.",
      ad2: "Garantiza una mesa.",
      ad3: "Mejora tu organización.",
      ad4: "Incrementa los beneficios.",
      imgEntrep: "../../../img/calendario.png",
    },
    {
      service: "Formulario de contacto",
      ad1: "Resuelve las dudas de tus clientes.",
      ad2: "Promueve servicio para eventos.",
      ad3: "Mayor cercanía con tus clientes.",
      ad4: "Incrementa la confianza.",
      imgEntrep: "../../../img/contact.png",
    },
    {
      service: "Almacenamiento de datos",
      ad1: "Almacena los datos de tus clientes.",
      ad2: "Conoce la opinión de cada visitante.",
      ad3: "Establece un perfil de tus clientes.",
      ad4: "Lanza ofertas a través de correos electrónicos.",
      imgEntrep: "../../../img/bbdd.png",
    },
    {
      service: "Sé el 1º en Google",
      ad1: "Aparece en la primera página de Google.",
      ad2: "Aumenta el alcance de clientes.",
      ad3: "Destaca sobre tu competencia.",
      ad4: "Incrementa los beneficios.",
      imgEntrep: "../../../img/seoBlack.png",
    },
    {
      service: "Alcance mundial",
      ad1: "Sin limitaciones.",
      ad2: "Vende a cualquier parte del mundo.",
      ad3: "Promueve mejor tus productos/servicios.",
      ad4: "Incrementa tus clientes.",
      imgEntrep: "../../../img/mundial.png",
    },
    {
      service: "Relación costo-beneficio",
      ad1: "Se crea una vez, se disfruta 24/7.",
      ad2: "Vende a cualquier parte del mundo.",
      ad3: "Promueve mejor tus productos/servicios.",
      ad4: "Incrementa tus ganancias.",
      imgEntrep: "../../../img/costProfit.png",
    },   
    {
      service: "Destácate",
      ad1: "Diferénciate de tu competencia.",
      ad2: "Ofrece un servicio más cercano.",
      ad3: "No seas el último de tu sector.",
      ad4: "Digitaliza y avanza.",
      imgEntrep: "../../../img/numberOne.png",
    },
    {
      service: "El dinero no duerme",
      ad1: "Una tienda las 24 horas.",
      ad2: "Los 7 días de la semana.",
      ad3: "Los 365 días del año.",
      ad4: "Con una sola inversión.",
      imgEntrep: "../../../img/dontsleep.png",
    },
  ];
  const effectFontList = (idElement, duration) => {
    document.getElementById(idElement).animate(
      [
        // fotogramas clave
        { opacity: "0.1" },
        { opacity: "1" },
        
      ],
      {
        // opciones de sincronización
        duration: duration,
      }
    );
  };
  const effectImgList = (idElement, duration) => {
    document.getElementById(idElement).animate(
      [
        // fotogramas clave
        { opacity: "0.3" },
        { opacity: "0.5" },
      ],
      {
        // opciones de sincronización
        duration: duration,
      }
    );
  };

  const effectButtonList = (idElement, duration) => {
    document
      .getElementById(idElement)
      .animate(
        [
          { transform: "rotate(0deg)" },
          { color: "orange" },
          { transform: "rotate(360deg)" },
        ],
        {
          // opciones de sincronización
          duration: duration,
        }
      );
  };

  const iterarArray = () => {
    document.getElementById("titleService").innerHTML = "SERVICIO";
    document.getElementById("service").innerHTML =
      tableEntrep[indexArray].service;
    document.getElementById("titleAd").innerHTML = "VENTAJAS";
    document.getElementById("ad1").innerHTML = tableEntrep[indexArray].ad1;
    document.getElementById("ad2").innerHTML = tableEntrep[indexArray].ad2;
    document.getElementById("ad3").innerHTML = tableEntrep[indexArray].ad3;
    document.getElementById("ad4").innerHTML = tableEntrep[indexArray].ad4;
    document.getElementById("imgEntrep").src =
      tableEntrep[indexArray].imgEntrep;

    effectFontList("service", 500);
    effectFontList("ad1", 1000);
    effectFontList("ad2", 1500);
    effectFontList("ad3", 2000);
    effectFontList("ad4", 2500);
    effectImgList("imgEntrep", 2000);
  };

  if (indexArray == tableEntrep.length) {
    setIndexArray(0);
  }

  const next = () => {
    effectButtonList("buttonNext", 1000);
    if (reasons < tableEntrep.length) {
      setReasons(reasons + 1);
    }
    setIndexArray(indexArray + 1);
    if (indexArray == tableEntrep.length - 1) {
      setIndexArray(0);
    }
    iterarArray();
  };

  const prev = () => {
    effectButtonList("buttonPrev", 1000);
    if (reasons < tableEntrep.length) {
      setReasons(reasons + 1);
    }

    setIndexArray(indexArray - 1);

    if (indexArray == 0) {
      setIndexArray(tableEntrep.length -1);

    }
    iterarArray();
  };

  setTimeout(iterarArray, 0);

  return (
    <div className="sectionEntrep">
      <div className="container-fluid">
        <h1 className="titleEntrep colorSpecial fontL">BENEFICIOS DE TENER UNA WEB</h1>
        <div className="bodySectionEntrep">
          <div className="row">
            

            <div className="bodyEntrep col-12">
              <ul className="listEntrep fontS">
                <li id="titleService" className="colorSpecial fontM"></li>
                <li className="fontM" id="service"></li>

                <li id="titleAd" className="colorSpecial fontM"></li>

                <li className="rowListEntrep" id="ad1"></li>
                <li className="rowListEntrep" id="ad2"></li>
                <li className="rowListEntrep" id="ad3"></li>
                <li className="rowListEntrep" id="ad4"></li>
              </ul>
            </div>

           
          </div>
          <div className="row">

<div className="reasonsWithImg">
<button
              type="button"
              id="buttonPrev"
              className="buttonPrev col-1"
              onClick={() => prev()}
            >
              <IoIosArrowDropleft size={45} />
            </button>

      
            
            <img
              id="imgEntrep"
              src="../../../img/qr.png"
              alt={tableEntrep[indexArray].imgEntrep}
            />

<button
              type="button"
              id="buttonNext"
              className="buttonNext col-1"
              onClick={next}
            >
              <IoIosArrowDropright size={45} />
            </button>
            <div className="row">
            <span id="reasonsWeb" className="reasonsWeb">
              {reasons} RAZONES PARA TENER UNA WEB</span>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
  );
};

export default BodyEntrep;
