import React from 'react'

const Skills = () => {
  return (
    <table className='descriptionMe'>
    <thead>
            <tr>
                <th className='titlesAboutme colorSpecial'>Conocimientos en</th>
            </tr>
        </thead>
        <tbody>
        
            <tr>
                <td>React JS</td>
            </tr>
            <tr>
                <td>Node JS</td>
            </tr>
            <tr>
                <td>JavaScript / JQuery</td>
            </tr>
            <tr>
                <td>TypeScript</td>
            </tr>
            <tr>
                <td>PHP / SQL</td>
            </tr>
            <tr>
                <td>Css / Sass</td>
            </tr>
            <tr>
                <td>HTML</td>
            </tr>
            <tr>
                <td>WordPress</td>
            </tr>
            <tr>
                <td>Photoshop</td>
            </tr>
            <tr>
                <td>Excel / Word</td>
            </tr>
               
        </tbody>
    
    </table>
  )
}

export default Skills