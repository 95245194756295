import React from 'react'
import './body.scss';
import BodyPresentation from './body1/bodyPresentation';
import BodyEntrep from './body2/bodyEntrep';
import BodyMe from './body4me/bodyMe';

const Body = () => {
  return (
    <div className='body'>


      <BodyPresentation />
      <BodyEntrep />
      <BodyMe />

    </div>
  )
}

export default Body