import Body from "./components/body/body";
import Footer from "./components/footer/footer";
import Main from "./components/main/main";

const Home = () => {

   
return (
<>
    <Main />
    <Body />
    <Footer />
</>
)
};
export default Home;

