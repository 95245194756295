import React from 'react'
import GoBackToHome from './goBackToHome'

const CookiesPrivacy = () => {
  return (
    <div>
    <GoBackToHome />
    <span className='bodyPrivacy'>

    Política de Cookies
    <br/><br/>
En esta web nos preocupamos por la privacidad de nuestros clientes, y por ello no hacemos uso de cookies.
    </span> </div>
  )
}

export default CookiesPrivacy