import React from 'react';
import './bodyPresentation.scss';
import '../body.scss';
import DesignWeb from './designWeb';
import Maintenance from './maintenance';
import Seo from './seo';
import DevelopWeb from './developWeb';


const BodyPresentation = () => {




  return (

    <div className='container-fluid'>
    <div className='bodyPresentation' id='bodyPresentation'>
      <h1 className='titleSectionBodyPresentation colorSpecial fontL' id='titleSectionBodyPresentation'>TU WEB EN 4 PASOS:</h1>

            <DesignWeb/>
            <DevelopWeb />
            <Maintenance />
            <Seo />

        </div>
    </div>
  )
}

export default BodyPresentation