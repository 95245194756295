import React, { useState } from 'react';
import './bodyMe.scss';
import '../body.scss';
import AboutMe from './sections4me/aboutMe';
import Education from './sections4me/education';
import Skills from './sections4me/skills';
import Portfolio from './sections4me/portfolio';
import {IoIosArrowDropdown} from 'react-icons/io';
import {IoIosArrowDropup} from 'react-icons/io';


const BodyMe = () => {

    const [optionMe, setOptionMe] = useState('')
    const [showHide, setShowHide] = useState(false);

    const showOption = (option) =>{
        if(option === optionMe){setOptionMe('')}
        else{
        setOptionMe(option)};
    }


    const showMe = () => {
        setShowHide(!showHide);
        setOptionMe('');
    }

    return (
        <>
        <h1 className='fontL colorSpecial titleMe'><button className='buttonHideShow buttonShowMe' onClick={showMe}> SOBRE MI  {showHide ? <IoIosArrowDropup size={40} /> : <IoIosArrowDropdown size={40}/> }</button></h1>  
        
                {showHide ? 
        <div className='bodyPresentation me'>
            <div className='container-fluid bodySection'>
            
        
               
                    

                <div className='row'>

                        <div className='sectionPhoto bodyMe col-12'>

                            <nav className='menu'>
                                    <button type='button' name='sobreMi' className='buttonMe' onClick={() => showOption('sobreMi')}>Sobre mi</button>
                                    <button type='button' name='estudios' className='buttonMe' onClick={() => showOption('educacion')}>Educación</button>
                                    <button type='button' name='habilidades' className='buttonMe' onClick={() => showOption('habilidades')}>Habilidades</button>
                                    <button type='button' name='experienciaLaboral' className='buttonMe' onClick={() => showOption('experiencia')}>Portfolio</button>
                            </nav>
                        </div>
                        <div className='col-12'>
                        {optionMe === 'sobreMi' ? <AboutMe /> : false}
                        {optionMe === 'educacion' ? <Education /> : false}
                        {optionMe === 'habilidades' ? <Skills /> : false}
                        {optionMe === 'experiencia' ? <Portfolio />  : false}
                        </div>
                        </div> 
            </div>
        </div>
        : false}
        </>
      )
    }

export default BodyMe