import React from 'react'
import Contact from './contact'
import './footer.scss'
import {Link} from 'react-router-dom'

const Footer = () => {
  return (
    <div>
        <Contact />
            <div className="sectionFooter">
            <div className='footer'>
                <Link to="/privacyCookies">Políticas de cookies</Link>
                <Link to="/generalPrivacy">Políticas de privacidad</Link>
                <Link to="/legalPrivacy">Aviso legal</Link>
                <h5 className='author'> © Copyright 2022 - Romel Zela</h5>
            </div>
        </div>
    </div>
  )
}

export default Footer