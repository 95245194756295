import React from "react";

const DevelopWeb = () => {
  const descriptionDevelop = (
    <>
      <h2 className="description descriptionRight fontS">
        Elegir la tecnología adecuada para desarrollar un sitio web es una tarea
        muy importante. Existen muchas alternativas como: WordPress, PrestaShop
        y lenguajes de programación como JavaScript (ReactJS / NodeJS).
        <br />
        <br />
        <strong>
          Estudiaremos a profundidad los requerimientos de cada cliente y
          elegiremos la tecnología que mejor se adapte.
        </strong>
      </h2>
    </>
  );

  const imgDevelop = <div className="imgBody imgDevelop imgPCLeft"></div>;
  

  return (
    <div className="row">
      <h1 className="titleSection titleSectionRight colorSpecial fontL">
        <span className="fontS">2.</span>DESAROLLO
      </h1>
      <div className="col-12 col-md-6 imgIgnoreMovil">{imgDevelop}</div>
      <div className="col-12 col-md-6">{descriptionDevelop}</div>
      <div className="col-12 col-md-6 imgIgnorePC">{imgDevelop}</div>
    </div>
  );
};
export default DevelopWeb;
